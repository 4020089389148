import BaseRecommendApiRequestDto from "./BaseRecommendApiRequestDto";

export default class GetRecommendRequestDto extends BaseRecommendApiRequestDto {
	//
	goodsCode = undefined;
	type = undefined;
	userId = undefined;
	fromPrice = undefined;
	toPrice = undefined;
	typeCode = undefined;
	fixedDisplayGoodsCode = undefined;
	reviewRatingMinRating = undefined;
	reviewCountMinCount = undefined;
	withReviewOnly = false;

	constructor(showRecommendParams) {
		super();

		for (const key in this) {
			this[key] = showRecommendParams[key];
		}
	}
}
