import BaseRecommendApiRequestDto from "./BaseRecommendApiRequestDto";

export default class GetRankingRequestDto extends BaseRecommendApiRequestDto {
	//
	type = undefined;
	span = undefined;
	typeCode = undefined;
	reviewRatingMinRating = undefined;
	reviewCountMinCount = undefined;
	withReviewOnly = false;

	constructor(showRankingParams) {
		super();

		for (const key in this) {
			this[key] = showRankingParams[key];
		}
	}
}
