import BaseRecommendApiRequestDto from "./BaseRecommendApiRequestDto";

export default class GetSoldRequestDto extends BaseRecommendApiRequestDto {
	//
	reviewRatingMinRating = undefined;
	reviewCountMinCount = undefined;
	withReviewOnly = false;
	
	constructor(showRecommendParams) {
		super();
		
		for (const key in this) {
			this[key] = showRecommendParams[key];
		}
	}
}
