import BaseRecommendApiRequestDto from "./BaseRecommendApiRequestDto";

export default class GetSimilarRequestDto extends BaseRecommendApiRequestDto {
	//
	goodsCode = undefined;
	fromPrice = undefined;
	toPrice = undefined;
	reviewRatingMinRating = undefined;
	reviewCountMinCount = undefined;
	withReviewOnly = false;

	constructor(showRecommendParams) {
		super();

		for (const key in this) {
			this[key] = showRecommendParams[key];
		}
	}
}
